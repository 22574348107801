import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Byte: any;
  Currency: any;
  Date: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  SafeInt: any;
  Time: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

export type AdminReport = {
  __typename?: 'AdminReport';
  users: Array<User>;
};



export type ChartDataDateRange = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type ChartDataFilter = {
  column: Scalars['String'];
  value: Scalars['String'];
};

export type ChartDataInput = {
  dateRange: ChartDataDateRange;
  eventType: Scalars['String'];
  filters?: Maybe<Array<ChartDataFilter>>;
  granularity?: Maybe<Scalars['String']>;
  groupBy?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  uniqueVisitor?: Maybe<Scalars['Boolean']>;
};

export type ChartDataResults = {
  __typename?: 'ChartDataResults';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type CheckScriptHeaderResults = {
  __typename?: 'CheckScriptHeaderResults';
  errorMessage?: Maybe<Scalars['String']>;
  found: Scalars['Boolean'];
  headerContent: Scalars['String'];
};

export type CmsMetricValue = {
  __typename?: 'CmsMetricValue';
  name: Scalars['String'];
  value: Scalars['PositiveInt'];
  webflowItemId: Scalars['String'];
};

export type CreateDashboardInput = {
  name: Scalars['String'];
  siteId: Scalars['String'];
};

export type CreateMetricEventInput = {
  cssClass?: Maybe<Scalars['String']>;
  cssId?: Maybe<Scalars['String']>;
  eventType: EventType;
  id?: Maybe<Scalars['UUID']>;
  locationHref: Scalars['String'];
  referer?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  visitor: VisitorInput;
};

export type CreateMetricInput = {
  breakdownBy?: Maybe<MetricChartBreakdownBy>;
  cssSelector?: Maybe<Scalars['String']>;
  dashboardId: Scalars['String'];
  metricType: MetricType;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  uniqueVisitor?: Maybe<Scalars['Boolean']>;
  webflowCollectionId?: Maybe<Scalars['String']>;
};

export type CreateSiteGridLayoutItemInput = {
  h: Scalars['PositiveInt'];
  id: Scalars['String'];
  w: Scalars['PositiveInt'];
  x: Scalars['PositiveInt'];
  y: Scalars['PositiveInt'];
};


export type Dashboard = {
  __typename?: 'Dashboard';
  createdAt: Scalars['Date'];
  gridLayout?: Maybe<Array<SiteGridLayoutItem>>;
  id: Scalars['String'];
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  organisationId: Scalars['String'];
  siteId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type DashboardGridLayoutItem = {
  __typename?: 'DashboardGridLayoutItem';
  h: Scalars['PositiveInt'];
  id: Scalars['String'];
  w: Scalars['PositiveInt'];
  x: Scalars['PositiveInt'];
  y: Scalars['PositiveInt'];
};





export enum EventType {
  Click = 'click',
  Cms = 'cms',
  Form = 'form',
  Keyboard = 'keyboard',
  View = 'view'
}

export type EventsFilters = {
  eventType: EventType;
  siteId: Scalars['ID'];
  visitorId: Scalars['ID'];
};

export enum Feature {
  EmbeddableDashboard = 'EmbeddableDashboard',
  HistoricalData = 'HistoricalData',
  LiveData = 'LiveData',
  Membership = 'Membership',
  OneMillionVisitors = 'OneMillionVisitors',
  OneThousandVisitors = 'OneThousandVisitors',
  TenThousandVisitors = 'TenThousandVisitors',
  UnlimitedMetrics = 'UnlimitedMetrics',
  UnlimitedSites = 'UnlimitedSites'
}

export type Features = {
  __typename?: 'Features';
  memberFilter: Scalars['Boolean'];
};





















export type Me = {
  __typename?: 'Me';
  notifications: Array<Notifications>;
  organisations: Array<MeOrganisation>;
  user: User;
};

export type MeOrganisation = {
  __typename?: 'MeOrganisation';
  id: Scalars['String'];
};

export type Metric = {
  __typename?: 'Metric';
  breakdownBy?: Maybe<MetricChartBreakdownBy>;
  chartData?: Maybe<MetricChart>;
  cssSelector?: Maybe<Scalars['String']>;
  dashboardId: Scalars['String'];
  id: Scalars['ID'];
  metricType: MetricType;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  uniqueVisitor?: Maybe<Scalars['Boolean']>;
  webflowCollectionId?: Maybe<Scalars['String']>;
};


export type MetricChartDataArgs = {
  input: MetricChartInput;
};

export type MetricByValue = {
  __typename?: 'MetricByValue';
  name: Scalars['String'];
  value: Scalars['PositiveInt'];
};

export type MetricChart = {
  __typename?: 'MetricChart';
  data: Array<MetricChartData>;
  delta?: Maybe<Scalars['Int']>;
  fromCache?: Maybe<Scalars['Boolean']>;
  lastSync?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  queryTime?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
};

export enum MetricChartBreakdownBy {
  City = 'City',
  ClientName = 'ClientName',
  CmsWebflowItemId = 'CmsWebflowItemId',
  Country = 'Country',
  CssClass = 'CssClass',
  CssId = 'CssId',
  DeviceBrand = 'DeviceBrand',
  DeviceModel = 'DeviceModel',
  DeviceType = 'DeviceType',
  None = 'None',
  OsName = 'OsName',
  Path = 'Path',
  Referer = 'Referer',
  Value = 'Value'
}

export type MetricChartData = {
  __typename?: 'MetricChartData';
  name: Scalars['String'];
  values: Array<MetricChartDataValues>;
};

export type MetricChartDataValues = {
  __typename?: 'MetricChartDataValues';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type MetricChartInput = {
  breakdownBy?: Maybe<MetricChartBreakdownBy>;
  country?: Maybe<Scalars['String']>;
  cssSelector?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  isMember?: Maybe<Scalars['Boolean']>;
  memberId?: Maybe<Scalars['String']>;
  metricId?: Maybe<Scalars['String']>;
  metricType?: Maybe<MetricType>;
  path?: Maybe<Scalars['String']>;
  referer?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  skipCache?: Maybe<Scalars['Boolean']>;
  startAt?: Maybe<Scalars['String']>;
  timeframe?: Maybe<MetricTimeframe>;
  uniqueVisitor?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
  visitorId?: Maybe<Scalars['String']>;
  webflowCollectionId?: Maybe<Scalars['String']>;
};

export type MetricEvent = {
  __typename?: 'MetricEvent';
  cmsCollectionItemSlug?: Maybe<Scalars['String']>;
  cmsCollectionSlug?: Maybe<Scalars['String']>;
  cmsWebflowCollectionId?: Maybe<Scalars['String']>;
  cmsWebflowItemId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  cssClass?: Maybe<Scalars['String']>;
  cssId?: Maybe<Scalars['String']>;
  eventType: EventType;
  formDisplayedAt?: Maybe<Scalars['Date']>;
  formLastInteractionAt?: Maybe<Scalars['Date']>;
  formSubmittedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  path?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MetricFilters = {
  isMember?: Maybe<Scalars['Boolean']>;
};

export enum MetricInterval {
  Day = 'day',
  Hour = 'hour',
  Month = 'month'
}

export enum MetricTimeframe {
  Custom = 'Custom',
  Last3Months = 'Last3Months',
  Last6Months = 'Last6Months',
  Last7days = 'Last7days',
  Last30days = 'Last30days',
  LastYear = 'LastYear',
  Today = 'Today',
  Yesterday = 'Yesterday'
}

export enum MetricType {
  Click = 'Click',
  Cms = 'Cms',
  CustomField = 'CustomField',
  Form = 'Form',
  Keyboard = 'Keyboard',
  TopCountries = 'TopCountries',
  TopPages = 'TopPages',
  TopReferers = 'TopReferers',
  View = 'View',
  Visitors = 'Visitors'
}

export type MetricValue = {
  __typename?: 'MetricValue';
  date: Scalars['Date'];
  value: Scalars['PositiveInt'];
};

export type MetricValueResults = {
  __typename?: 'MetricValueResults';
  label?: Maybe<Scalars['String']>;
  values: Array<MetricValue>;
};

export type MetricValuesInput = {
  cssSelector?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['String']>;
  metricType: MetricType;
  path?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  startAt: Scalars['String'];
  webflowCollectionId?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  checkScriptHeader: CheckScriptHeaderResults;
  createDashboard: Dashboard;
  createMetric: Metric;
  createToken: Scalars['String'];
  deleteDashboard: Scalars['Boolean'];
  deleteDisabledSites: Scalars['Boolean'];
  deleteMetric: Scalars['String'];
  deleteSite: Scalars['String'];
  duplicateDashboard: Dashboard;
  notificationRead: Scalars['Boolean'];
  renewToken: Scalars['String'];
  reportError: Scalars['Boolean'];
  resetCacheBySiteId: Scalars['Boolean'];
  sendMagicLink: Scalars['Boolean'];
  signIn: Scalars['String'];
  signUpWithWebflow: Scalars['String'];
  syncSiteWithWebflow: Scalars['Boolean'];
  syncWebflowSites: Scalars['Boolean'];
  updateDashboard: Dashboard;
  updateMetric: Metric;
  updateSiteById: Site;
  updateSiteGridLayout?: Maybe<Site>;
};


export type MutationCheckScriptHeaderArgs = {
  siteId: Scalars['ID'];
};


export type MutationCreateDashboardArgs = {
  input: CreateDashboardInput;
};


export type MutationCreateMetricArgs = {
  metric: CreateMetricInput;
};


export type MutationCreateTokenArgs = {
  userId: Scalars['String'];
};


export type MutationDeleteDashboardArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDisabledSitesArgs = {
  organisationId: Scalars['ID'];
};


export type MutationDeleteMetricArgs = {
  metricId: Scalars['ID'];
};


export type MutationDeleteSiteArgs = {
  siteId: Scalars['ID'];
};


export type MutationDuplicateDashboardArgs = {
  id: Scalars['ID'];
};


export type MutationNotificationReadArgs = {
  subject: Scalars['String'];
};


export type MutationReportErrorArgs = {
  input: ReportErrorInput;
};


export type MutationResetCacheBySiteIdArgs = {
  siteId: Scalars['ID'];
};


export type MutationSendMagicLinkArgs = {
  email: Scalars['String'];
};


export type MutationSignInArgs = {
  token: Scalars['String'];
};


export type MutationSignUpWithWebflowArgs = {
  code: Scalars['String'];
};


export type MutationSyncSiteWithWebflowArgs = {
  siteId: Scalars['ID'];
};


export type MutationUpdateDashboardArgs = {
  input: UpdateDashboardInput;
};


export type MutationUpdateMetricArgs = {
  metric: UpdateMetricInput;
};


export type MutationUpdateSiteByIdArgs = {
  input: UpdateSiteByIdInput;
};


export type MutationUpdateSiteGridLayoutArgs = {
  newGridLayout: Array<CreateSiteGridLayoutItemInput>;
  siteId: Scalars['ID'];
};








export type Notifications = {
  __typename?: 'Notifications';
  body: Scalars['String'];
  createdAt: Scalars['Date'];
  readAt?: Maybe<Scalars['Date']>;
  subject: Scalars['String'];
};


export type Organisation = {
  __typename?: 'Organisation';
  features: Array<Feature>;
  id: Scalars['ID'];
};

export type OrganisationPlanExceeded = {
  __typename?: 'OrganisationPlanExceeded';
  block: Scalars['Boolean'];
  isFreePlanExceededContinuously: Scalars['Boolean'];
  isFreePlanVeryExceeded: Scalars['Boolean'];
  isPaying: Scalars['Boolean'];
  planExceeded: Scalars['Boolean'];
  viewsBySiteId: Array<ViewsBySiteId>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
};

export type Pagination = {
  __typename?: 'Pagination';
  hasMore: Scalars['Boolean'];
  total: Scalars['PositiveInt'];
};

export type PaginationInput = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['PositiveInt']>;
};






export type Query = {
  __typename?: 'Query';
  adminReport: AdminReport;
  chartData: MetricChart;
  chartDataV2?: Maybe<Array<ChartDataResults>>;
  cmsCollectionItems: Array<WebflowCollectionItems>;
  cmsCollections: Array<WebflowCollection>;
  dashboard: Dashboard;
  dashboardBySiteId: Array<Dashboard>;
  eventTypes: Array<EventType>;
  events: Array<MetricEvent>;
  features?: Maybe<Features>;
  maintenance: Scalars['Boolean'];
  me?: Maybe<Me>;
  metric: Metric;
  metricByValues: Array<MetricByValue>;
  metricTypes: Array<MetricType>;
  metricValues: Array<MetricValueResults>;
  metricsByDashboardId: Array<Metric>;
  metricsBySiteId: Array<Metric>;
  organisation: Organisation;
  planExceeded: OrganisationPlanExceeded;
  queueEvents?: Maybe<Array<Maybe<QueueEvent>>>;
  shortLiveToken: Scalars['String'];
  site: Site;
  /** @deprecated Use 'site' instead. */
  siteById: Site;
  siteByWebflowId: Site;
  siteHtmlElements: Array<SiteHtmlElement>;
  sites: SitePaginated;
  stripeCustomerPortalLink: Scalars['String'];
  users: UserPaginated;
  visitor?: Maybe<Visitor>;
  visitors: VisitorPaginated;
};


export type QueryChartDataArgs = {
  input: MetricChartInput;
};


export type QueryChartDataV2Args = {
  input: ChartDataInput;
};


export type QueryCmsCollectionItemsArgs = {
  collectionId: Scalars['ID'];
};


export type QueryCmsCollectionsArgs = {
  siteId: Scalars['ID'];
};


export type QueryDashboardArgs = {
  id: Scalars['String'];
};


export type QueryDashboardBySiteIdArgs = {
  siteId: Scalars['String'];
};


export type QueryEventsArgs = {
  filters: EventsFilters;
};


export type QueryMetricArgs = {
  id: Scalars['ID'];
};


export type QueryMetricByValuesArgs = {
  filters?: Maybe<MetricFilters>;
  input: MetricValuesInput;
};


export type QueryMetricValuesArgs = {
  filters?: Maybe<MetricFilters>;
  input: MetricValuesInput;
};


export type QueryMetricsByDashboardIdArgs = {
  dashboardById: Scalars['ID'];
};


export type QueryMetricsBySiteIdArgs = {
  siteId: Scalars['ID'];
};


export type QueryOrganisationArgs = {
  organisationId?: Maybe<Scalars['ID']>;
  siteId?: Maybe<Scalars['String']>;
};


export type QueryPlanExceededArgs = {
  organisationId: Scalars['ID'];
};


export type QueryQueueEventsArgs = {
  siteId: Scalars['String'];
};


export type QuerySiteArgs = {
  id: Scalars['ID'];
};


export type QuerySiteByIdArgs = {
  id: Scalars['ID'];
};


export type QuerySiteByWebflowIdArgs = {
  webflowId: Scalars['String'];
};


export type QuerySiteHtmlElementsArgs = {
  siteId: Scalars['ID'];
};


export type QuerySitesArgs = {
  cursor?: Maybe<Scalars['String']>;
  filters?: Maybe<SitesFilters>;
  first?: Maybe<Scalars['PositiveInt']>;
  organisationId: Scalars['String'];
};


export type QueryUsersArgs = {
  cursor?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['PositiveInt']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryVisitorArgs = {
  id: Scalars['ID'];
  siteId: Scalars['ID'];
};


export type QueryVisitorsArgs = {
  filters: VisitorsFilters;
  first?: Maybe<Scalars['PositiveInt']>;
  offset?: Maybe<Scalars['PositiveInt']>;
};

export type QueueEvent = {
  __typename?: 'QueueEvent';
  cmsItemIndex?: Maybe<Scalars['Int']>;
  cmsItemSlug?: Maybe<Scalars['String']>;
  cmsListIndex?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  cssClass?: Maybe<Scalars['String']>;
  cssId?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  formStage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  referer?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  visitorId?: Maybe<Scalars['String']>;
};



export type ReportErrorInput = {
  error?: Maybe<Scalars['JSON']>;
  from: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};


export type Site = {
  __typename?: 'Site';
  collections: Array<WebflowCollection>;
  createdAt: Scalars['Date'];
  dashboards?: Maybe<Array<Dashboard>>;
  domains?: Maybe<Array<Scalars['String']>>;
  eventSources?: Maybe<Array<Scalars['String']>>;
  gridLayout?: Maybe<Array<SiteGridLayoutItem>>;
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  metrics?: Maybe<Array<Metric>>;
  name: Scalars['String'];
  organisationId: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  totalMetricsCount?: Maybe<Scalars['PositiveInt']>;
  updatedAt: Scalars['Date'];
  webflowId: Scalars['String'];
};


export type SiteEventSourcesArgs = {
  filter?: Maybe<Scalars['String']>;
};

export type SiteEdge = {
  __typename?: 'SiteEdge';
  cursor: Scalars['String'];
  node: Site;
};

export type SiteGridLayoutItem = {
  __typename?: 'SiteGridLayoutItem';
  h: Scalars['PositiveInt'];
  id: Scalars['String'];
  w: Scalars['PositiveInt'];
  x: Scalars['PositiveInt'];
  y: Scalars['PositiveInt'];
};

export type SiteHtmlElement = {
  __typename?: 'SiteHtmlElement';
  html: Scalars['String'];
  id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

export type SitePaginated = {
  __typename?: 'SitePaginated';
  edges: Array<SiteEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['PositiveInt'];
};

export type SitesFilters = {
  name?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};








export type UpdateDashboardGridLayoutItem = {
  h: Scalars['PositiveInt'];
  id: Scalars['String'];
  w: Scalars['PositiveInt'];
  x: Scalars['PositiveInt'];
  y: Scalars['PositiveInt'];
};

export type UpdateDashboardInput = {
  gridLayout?: Maybe<Array<UpdateDashboardGridLayoutItem>>;
  id: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateMetricInput = {
  breakdownBy?: Maybe<MetricChartBreakdownBy>;
  cssSelector?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metricType?: Maybe<MetricType>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  uniqueVisitor?: Maybe<Scalars['Boolean']>;
  webflowCollectionId?: Maybe<Scalars['String']>;
};

export type UpdateSiteByIdInput = {
  id: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  firstname?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastname?: Maybe<Scalars['String']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: User;
};

export type UserPaginated = {
  __typename?: 'UserPaginated';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['PositiveInt'];
};


export type ViewsBySiteId = {
  __typename?: 'ViewsBySiteId';
  siteId: Scalars['String'];
  viewCount: Scalars['Int'];
};

export type Visitor = {
  __typename?: 'Visitor';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initialReferer?: Maybe<Scalars['String']>;
  lastInteraction: Scalars['Date'];
};

export type VisitorEdge = {
  __typename?: 'VisitorEdge';
  cursor: Scalars['String'];
  node: Visitor;
};

export type VisitorInput = {
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  memberstackId?: Maybe<Scalars['String']>;
  outsetaId?: Maybe<Scalars['String']>;
};

export type VisitorPaginated = {
  __typename?: 'VisitorPaginated';
  edges: Array<VisitorEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['PositiveInt'];
};

export type VisitorsFilters = {
  emailAddress?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['Date']>;
  isMember?: Maybe<Scalars['Boolean']>;
  siteId: Scalars['String'];
  startAt: Scalars['Date'];
  visitorId?: Maybe<Scalars['String']>;
};


export type WebflowCollection = {
  __typename?: 'WebflowCollection';
  fields: Array<WebflowCollectionFields>;
  id: Scalars['String'];
  items: Array<WebflowCollectionItems>;
  name: Scalars['String'];
};

export type WebflowCollectionFields = {
  __typename?: 'WebflowCollectionFields';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  type: Scalars['String'];
};

export type WebflowCollectionItems = {
  __typename?: 'WebflowCollectionItems';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type WebflowSites = {
  __typename?: 'WebflowSites';
  name: Scalars['String'];
};

export type SitesListContainerQueryVariables = Exact<{
  organisationId: Scalars['String'];
}>;


export type SitesListContainerQuery = { __typename?: 'Query', sites: { __typename?: 'SitePaginated', edges: Array<{ __typename?: 'SiteEdge', node: { __typename?: 'Site', id: string, name: string, isEnabled: boolean, previewUrl?: Maybe<string> } }> } };

export type AdminReportQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminReportQuery = { __typename?: 'Query', adminReport: { __typename?: 'AdminReport', users: Array<{ __typename?: 'User', id: string, email: string, firstname?: Maybe<string>, lastname?: Maybe<string>, createdAt: any }> } };

export type CreateTokenMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type CreateTokenMutation = { __typename?: 'Mutation', createToken: string };

export type ChartDataV2QueryVariables = Exact<{
  input: ChartDataInput;
}>;


export type ChartDataV2Query = { __typename?: 'Query', chartDataV2?: Maybe<Array<{ __typename?: 'ChartDataResults', label: string, value: number }>> };

export type DashboardFieldsFragment = { __typename?: 'Dashboard', id: string, siteId: string, organisationId: string, createdAt: any, updatedAt: any, name: string, isPublic: boolean, gridLayout?: Maybe<Array<{ __typename?: 'SiteGridLayoutItem', id: string, x: any, y: any, w: any, h: any }>> };

export type DashboardByIdQueryVariables = Exact<{
  dashboardId: Scalars['String'];
}>;


export type DashboardByIdQuery = { __typename?: 'Query', dashboard: { __typename?: 'Dashboard', id: string, siteId: string, organisationId: string, createdAt: any, updatedAt: any, name: string, isPublic: boolean, gridLayout?: Maybe<Array<{ __typename?: 'SiteGridLayoutItem', id: string, x: any, y: any, w: any, h: any }>> } };

export type DashboardBySiteIdQueryVariables = Exact<{
  siteId: Scalars['String'];
}>;


export type DashboardBySiteIdQuery = { __typename?: 'Query', dashboardBySiteId: Array<{ __typename?: 'Dashboard', id: string, siteId: string, organisationId: string, createdAt: any, updatedAt: any, name: string, isPublic: boolean, gridLayout?: Maybe<Array<{ __typename?: 'SiteGridLayoutItem', id: string, x: any, y: any, w: any, h: any }>> }> };

export type CreateDashboardMutationVariables = Exact<{
  input: CreateDashboardInput;
}>;


export type CreateDashboardMutation = { __typename?: 'Mutation', createDashboard: { __typename?: 'Dashboard', id: string, siteId: string, organisationId: string, createdAt: any, updatedAt: any, name: string, isPublic: boolean, gridLayout?: Maybe<Array<{ __typename?: 'SiteGridLayoutItem', id: string, x: any, y: any, w: any, h: any }>> } };

export type UpdateDashboardMutationVariables = Exact<{
  input: UpdateDashboardInput;
}>;


export type UpdateDashboardMutation = { __typename?: 'Mutation', updateDashboard: { __typename?: 'Dashboard', id: string, siteId: string, organisationId: string, createdAt: any, updatedAt: any, name: string, isPublic: boolean, gridLayout?: Maybe<Array<{ __typename?: 'SiteGridLayoutItem', id: string, x: any, y: any, w: any, h: any }>> } };

export type DeleteDashboardMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDashboardMutation = { __typename?: 'Mutation', deleteDashboard: boolean };

export type DuplicateDashboardMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DuplicateDashboardMutation = { __typename?: 'Mutation', duplicateDashboard: { __typename?: 'Dashboard', id: string } };

export type EventTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type EventTypesQuery = { __typename?: 'Query', eventTypes: Array<EventType> };

export type QueueEventFieldsFragment = { __typename?: 'QueueEvent', id?: Maybe<string>, ip?: Maybe<string>, path?: Maybe<string>, cssId?: Maybe<string>, value?: Maybe<string>, domain?: Maybe<string>, siteId?: Maybe<string>, referer?: Maybe<string>, cssClass?: Maybe<string>, createdAt?: Maybe<string>, eventType?: Maybe<string>, formStage?: Maybe<string>, userAgent?: Maybe<string>, visitorId?: Maybe<string>, cmsItemSlug?: Maybe<string>, cmsItemIndex?: Maybe<number>, cmsListIndex?: Maybe<number> };

export type QueueEventsQueryVariables = Exact<{
  siteId: Scalars['String'];
}>;


export type QueueEventsQuery = { __typename?: 'Query', queueEvents?: Maybe<Array<Maybe<{ __typename?: 'QueueEvent', id?: Maybe<string>, ip?: Maybe<string>, path?: Maybe<string>, cssId?: Maybe<string>, value?: Maybe<string>, domain?: Maybe<string>, siteId?: Maybe<string>, referer?: Maybe<string>, cssClass?: Maybe<string>, createdAt?: Maybe<string>, eventType?: Maybe<string>, formStage?: Maybe<string>, userAgent?: Maybe<string>, visitorId?: Maybe<string>, cmsItemSlug?: Maybe<string>, cmsItemIndex?: Maybe<number>, cmsListIndex?: Maybe<number> }>>> };

export type EventsQueryVariables = Exact<{
  filters: EventsFilters;
}>;


export type EventsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'MetricEvent', id: string, eventType: EventType, createdAt?: Maybe<any>, cssClass?: Maybe<string>, cssId?: Maybe<string>, value?: Maybe<string>, path?: Maybe<string>, cmsWebflowCollectionId?: Maybe<string>, cmsWebflowItemId?: Maybe<string>, cmsCollectionSlug?: Maybe<string>, cmsCollectionItemSlug?: Maybe<string>, formDisplayedAt?: Maybe<any>, formLastInteractionAt?: Maybe<any>, formSubmittedAt?: Maybe<any> }> };

export type ReportErrorMutationVariables = Exact<{
  input: ReportErrorInput;
}>;


export type ReportErrorMutation = { __typename?: 'Mutation', reportError: boolean };

export type PaginationFieldsFragment = { __typename?: 'Pagination', total: any, hasMore: boolean };

export type MaintenanceQueryVariables = Exact<{ [key: string]: never; }>;


export type MaintenanceQuery = { __typename?: 'Query', maintenance: boolean };

export type MetricTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type MetricTypesQuery = { __typename?: 'Query', metricTypes: Array<MetricType> };

export type MetricValueFieldsFragment = { __typename?: 'MetricValue', date: any, value: any };

export type MetricFieldsFragment = { __typename?: 'Metric', id: string, name: string, metricType: MetricType, cssSelector?: Maybe<string>, path?: Maybe<string>, webflowCollectionId?: Maybe<string>, breakdownBy?: Maybe<MetricChartBreakdownBy>, uniqueVisitor?: Maybe<boolean>, dashboardId: string };

export type MetricsBySiteIdQueryVariables = Exact<{
  siteId: Scalars['ID'];
}>;


export type MetricsBySiteIdQuery = { __typename?: 'Query', metricsBySiteId: Array<{ __typename?: 'Metric', id: string, name: string, metricType: MetricType, cssSelector?: Maybe<string>, path?: Maybe<string>, webflowCollectionId?: Maybe<string>, breakdownBy?: Maybe<MetricChartBreakdownBy>, uniqueVisitor?: Maybe<boolean>, dashboardId: string }> };

export type MetricValuesQueryVariables = Exact<{
  metricValuesInput: MetricValuesInput;
  filters?: Maybe<MetricFilters>;
}>;


export type MetricValuesQuery = { __typename?: 'Query', metricValues: Array<{ __typename?: 'MetricValueResults', label?: Maybe<string>, values: Array<{ __typename?: 'MetricValue', date: any, value: any }> }> };

export type MetricByValuesQueryVariables = Exact<{
  metricValuesInput: MetricValuesInput;
  filters?: Maybe<MetricFilters>;
}>;


export type MetricByValuesQuery = { __typename?: 'Query', metricByValues: Array<{ __typename?: 'MetricByValue', name: string, value: any }> };

export type MetricByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MetricByIdQuery = { __typename?: 'Query', metric: { __typename?: 'Metric', id: string, name: string, metricType: MetricType, cssSelector?: Maybe<string>, path?: Maybe<string>, webflowCollectionId?: Maybe<string>, breakdownBy?: Maybe<MetricChartBreakdownBy>, uniqueVisitor?: Maybe<boolean>, dashboardId: string } };

export type CreateMetricMutationVariables = Exact<{
  metric: CreateMetricInput;
}>;


export type CreateMetricMutation = { __typename?: 'Mutation', createMetric: { __typename?: 'Metric', id: string, name: string, metricType: MetricType, cssSelector?: Maybe<string>, path?: Maybe<string>, webflowCollectionId?: Maybe<string>, breakdownBy?: Maybe<MetricChartBreakdownBy>, uniqueVisitor?: Maybe<boolean>, dashboardId: string } };

export type UpdateMetricMutationVariables = Exact<{
  metric: UpdateMetricInput;
}>;


export type UpdateMetricMutation = { __typename?: 'Mutation', updateMetric: { __typename?: 'Metric', id: string, name: string, metricType: MetricType, cssSelector?: Maybe<string>, path?: Maybe<string>, webflowCollectionId?: Maybe<string>, breakdownBy?: Maybe<MetricChartBreakdownBy>, uniqueVisitor?: Maybe<boolean>, dashboardId: string } };

export type DeleteMetricMutationVariables = Exact<{
  metricId: Scalars['ID'];
}>;


export type DeleteMetricMutation = { __typename?: 'Mutation', deleteMetric: string };

export type ChartDataQueryVariables = Exact<{
  input: MetricChartInput;
}>;


export type ChartDataQuery = { __typename?: 'Query', chartData: { __typename?: 'MetricChart', name: string, delta?: Maybe<number>, data: Array<{ __typename?: 'MetricChartData', name: string, values: Array<{ __typename?: 'MetricChartDataValues', label: string, value: number }> }> } };

export type MetricsByDashboardIdQueryVariables = Exact<{
  dashboardById: Scalars['ID'];
}>;


export type MetricsByDashboardIdQuery = { __typename?: 'Query', metricsByDashboardId: Array<{ __typename?: 'Metric', id: string, name: string, metricType: MetricType, cssSelector?: Maybe<string>, path?: Maybe<string>, webflowCollectionId?: Maybe<string>, breakdownBy?: Maybe<MetricChartBreakdownBy>, uniqueVisitor?: Maybe<boolean>, dashboardId: string }> };

export type OrganisationFieldsFragment = { __typename?: 'Organisation', id: string, features: Array<Feature> };

export type OrganisationQueryVariables = Exact<{
  organisationId?: Maybe<Scalars['ID']>;
  siteId?: Maybe<Scalars['String']>;
}>;


export type OrganisationQuery = { __typename?: 'Query', organisation: { __typename?: 'Organisation', id: string, features: Array<Feature> } };

export type PlanExceededQueryVariables = Exact<{
  organisationId: Scalars['ID'];
}>;


export type PlanExceededQuery = { __typename?: 'Query', planExceeded: { __typename?: 'OrganisationPlanExceeded', planExceeded: boolean, isFreePlanVeryExceeded: boolean, isFreePlanExceededContinuously: boolean, block: boolean, viewsBySiteId: Array<{ __typename?: 'ViewsBySiteId', siteId: string, viewCount: number }> } };

export type SiteFieldsFragment = { __typename?: 'Site', id: string, name: string, isEnabled: boolean, isPublic: boolean, organisationId: string, shortName?: Maybe<string>, previewUrl?: Maybe<string>, createdAt: any, updatedAt: any };

export type SitesQueryVariables = Exact<{
  filters?: Maybe<SitesFilters>;
  first?: Maybe<Scalars['PositiveInt']>;
  cursor?: Maybe<Scalars['String']>;
  organisationId: Scalars['String'];
}>;


export type SitesQuery = { __typename?: 'Query', sites: { __typename?: 'SitePaginated', totalCount: any, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges: Array<{ __typename?: 'SiteEdge', cursor: string, node: { __typename?: 'Site', id: string, name: string, isEnabled: boolean, isPublic: boolean, organisationId: string, shortName?: Maybe<string>, previewUrl?: Maybe<string>, createdAt: any, updatedAt: any } }> } };

export type SiteByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SiteByIdQuery = { __typename?: 'Query', siteById: { __typename?: 'Site', id: string, name: string, isEnabled: boolean, isPublic: boolean, organisationId: string, shortName?: Maybe<string>, previewUrl?: Maybe<string>, createdAt: any, updatedAt: any } };

export type SiteByWebflowIdQueryVariables = Exact<{
  webflowId: Scalars['String'];
}>;


export type SiteByWebflowIdQuery = { __typename?: 'Query', siteByWebflowId: { __typename?: 'Site', id: string, name: string, isEnabled: boolean, isPublic: boolean, organisationId: string, shortName?: Maybe<string>, previewUrl?: Maybe<string>, createdAt: any, updatedAt: any } };

export type SiteHtmlElementsQueryVariables = Exact<{
  siteId: Scalars['ID'];
}>;


export type SiteHtmlElementsQuery = { __typename?: 'Query', siteHtmlElements: Array<{ __typename?: 'SiteHtmlElement', html: string, id: string, text?: Maybe<string> }> };

export type SiteDomainsQueryVariables = Exact<{
  siteId: Scalars['ID'];
}>;


export type SiteDomainsQuery = { __typename?: 'Query', siteDomains: { __typename?: 'Site', domains?: Maybe<Array<string>> } };

export type CmsCollectionsQueryVariables = Exact<{
  siteId: Scalars['ID'];
}>;


export type CmsCollectionsQuery = { __typename?: 'Query', cmsCollections: Array<{ __typename?: 'WebflowCollection', id: string, name: string, fields: Array<{ __typename?: 'WebflowCollectionFields', id: string, name: string, slug: string, type: string }> }> };

export type CmsCollectionsItemsQueryVariables = Exact<{
  collectionId: Scalars['ID'];
}>;


export type CmsCollectionsItemsQuery = { __typename?: 'Query', cmsCollectionItems: Array<{ __typename?: 'WebflowCollectionItems', id: string, name: string }> };

export type CheckScriptHeaderMutationVariables = Exact<{
  siteId: Scalars['ID'];
}>;


export type CheckScriptHeaderMutation = { __typename?: 'Mutation', checkScriptHeader: { __typename?: 'CheckScriptHeaderResults', found: boolean, headerContent: string, errorMessage?: Maybe<string> } };

export type UpdateSiteByIdMutationVariables = Exact<{
  input: UpdateSiteByIdInput;
}>;


export type UpdateSiteByIdMutation = { __typename?: 'Mutation', updateSiteById: { __typename?: 'Site', id: string, name: string, isEnabled: boolean, isPublic: boolean, organisationId: string, shortName?: Maybe<string>, previewUrl?: Maybe<string>, createdAt: any, updatedAt: any } };

export type SyncSiteWithWebflowMutationVariables = Exact<{
  siteId: Scalars['ID'];
}>;


export type SyncSiteWithWebflowMutation = { __typename?: 'Mutation', syncSiteWithWebflow: boolean };

export type DeleteSiteMutationVariables = Exact<{
  siteId: Scalars['ID'];
}>;


export type DeleteSiteMutation = { __typename?: 'Mutation', deleteSite: string };

export type DeleteDisabledSitesMutationVariables = Exact<{
  organisationId: Scalars['ID'];
}>;


export type DeleteDisabledSitesMutation = { __typename?: 'Mutation', deleteDisabledSites: boolean };

export type StripeCustomerPortalLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type StripeCustomerPortalLinkQuery = { __typename?: 'Query', stripeCustomerPortalLink: string };

export type UserFieldsFragment = { __typename?: 'User', id: string, email: string, firstname?: Maybe<string>, lastname?: Maybe<string>, createdAt: any };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', me?: Maybe<{ __typename?: 'Me', user: { __typename?: 'User', id: string, email: string, firstname?: Maybe<string>, lastname?: Maybe<string>, createdAt: any }, organisations: Array<{ __typename?: 'MeOrganisation', id: string }> }> };

export type NotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsQuery = { __typename?: 'Query', me?: Maybe<{ __typename?: 'Me', notifications: Array<{ __typename?: 'Notifications', subject: string, body: string, createdAt: any, readAt?: Maybe<any> }> }> };

export type UsersQueryVariables = Exact<{
  first?: Maybe<Scalars['PositiveInt']>;
  cursor?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UserPaginated', totalCount: any, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges: Array<{ __typename?: 'UserEdge', cursor: string, node: { __typename?: 'User', id: string, email: string, firstname?: Maybe<string>, lastname?: Maybe<string>, createdAt: any } }> } };

export type FeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type FeaturesQuery = { __typename?: 'Query', features?: Maybe<{ __typename?: 'Features', memberFilter: boolean }> };

export type ShortLiveTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type ShortLiveTokenQuery = { __typename?: 'Query', shortLiveToken: string };

export type SignUpWithWebflowMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type SignUpWithWebflowMutation = { __typename?: 'Mutation', signUpWithWebflow: string };

export type SignInMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type SignInMutation = { __typename?: 'Mutation', signIn: string };

export type RenewTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RenewTokenMutation = { __typename?: 'Mutation', renewToken: string };

export type SendMagicLinkMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendMagicLinkMutation = { __typename?: 'Mutation', sendMagicLink: boolean };

export type NotificationReadMutationVariables = Exact<{
  subject: Scalars['String'];
}>;


export type NotificationReadMutation = { __typename?: 'Mutation', notificationRead: boolean };

export type VisitorFieldsFragment = { __typename?: 'Visitor', id: string, emailAddress?: Maybe<string>, lastInteraction: any };

export type VisitorsQueryVariables = Exact<{
  filters: VisitorsFilters;
  first?: Maybe<Scalars['PositiveInt']>;
  offset?: Maybe<Scalars['PositiveInt']>;
}>;


export type VisitorsQuery = { __typename?: 'Query', visitors: { __typename?: 'VisitorPaginated', totalCount: any, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges: Array<{ __typename?: 'VisitorEdge', cursor: string, node: { __typename?: 'Visitor', id: string, emailAddress?: Maybe<string>, lastInteraction: any } }> } };

export type VisitorQueryVariables = Exact<{
  id: Scalars['ID'];
  siteId: Scalars['ID'];
}>;


export type VisitorQuery = { __typename?: 'Query', visitor?: Maybe<{ __typename?: 'Visitor', id: string, emailAddress?: Maybe<string>, city?: Maybe<string>, country?: Maybe<string>, device?: Maybe<string>, lastInteraction: any, initialReferer?: Maybe<string> }> };

export type SiteDetailsPageQueryVariables = Exact<{
  siteId: Scalars['ID'];
}>;


export type SiteDetailsPageQuery = { __typename?: 'Query', site: { __typename?: 'Site', id: string, name: string, isEnabled: boolean, gridLayout?: Maybe<Array<{ __typename?: 'SiteGridLayoutItem', id: string, x: any, y: any, w: any, h: any }>>, metrics?: Maybe<Array<{ __typename?: 'Metric', id: string, name: string, metricType: MetricType, cssSelector?: Maybe<string>, path?: Maybe<string>, webflowCollectionId?: Maybe<string>, breakdownBy?: Maybe<MetricChartBreakdownBy>, uniqueVisitor?: Maybe<boolean>, dashboardId: string }>> } };

export const DashboardFieldsFragmentDoc = gql`
    fragment DashboardFields on Dashboard {
  id
  siteId
  organisationId
  createdAt
  updatedAt
  name
  isPublic
  gridLayout {
    id
    x
    y
    w
    h
  }
}
    `;
export const QueueEventFieldsFragmentDoc = gql`
    fragment QueueEventFields on QueueEvent {
  id
  ip
  path
  cssId
  value
  domain
  siteId
  referer
  cssClass
  createdAt
  eventType
  formStage
  userAgent
  visitorId
  cmsItemSlug
  cmsItemIndex
  cmsListIndex
}
    `;
export const PaginationFieldsFragmentDoc = gql`
    fragment PaginationFields on Pagination {
  total
  hasMore
}
    `;
export const MetricValueFieldsFragmentDoc = gql`
    fragment MetricValueFields on MetricValue {
  date
  value
}
    `;
export const MetricFieldsFragmentDoc = gql`
    fragment MetricFields on Metric {
  id
  name
  metricType
  cssSelector
  path
  webflowCollectionId
  breakdownBy
  uniqueVisitor
  dashboardId
}
    `;
export const OrganisationFieldsFragmentDoc = gql`
    fragment OrganisationFields on Organisation {
  id
  features
}
    `;
export const SiteFieldsFragmentDoc = gql`
    fragment SiteFields on Site {
  id
  name
  isEnabled
  isPublic
  organisationId
  shortName
  previewUrl
  createdAt
  updatedAt
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  email
  firstname
  lastname
  createdAt
}
    `;
export const VisitorFieldsFragmentDoc = gql`
    fragment VisitorFields on Visitor {
  id
  emailAddress
  lastInteraction
}
    `;
export const SitesListContainerDocument = gql`
    query SitesListContainer($organisationId: String!) {
  sites(organisationId: $organisationId) {
    edges {
      node {
        id
        name
        isEnabled
        previewUrl
      }
    }
  }
}
    `;

/**
 * __useSitesListContainerQuery__
 *
 * To run a query within a React component, call `useSitesListContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesListContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesListContainerQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useSitesListContainerQuery(baseOptions: Apollo.QueryHookOptions<SitesListContainerQuery, SitesListContainerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SitesListContainerQuery, SitesListContainerQueryVariables>(SitesListContainerDocument, options);
      }
export function useSitesListContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SitesListContainerQuery, SitesListContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SitesListContainerQuery, SitesListContainerQueryVariables>(SitesListContainerDocument, options);
        }
export type SitesListContainerQueryHookResult = ReturnType<typeof useSitesListContainerQuery>;
export type SitesListContainerLazyQueryHookResult = ReturnType<typeof useSitesListContainerLazyQuery>;
export type SitesListContainerQueryResult = Apollo.QueryResult<SitesListContainerQuery, SitesListContainerQueryVariables>;
export const AdminReportDocument = gql`
    query adminReport {
  adminReport {
    users {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useAdminReportQuery__
 *
 * To run a query within a React component, call `useAdminReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminReportQuery(baseOptions?: Apollo.QueryHookOptions<AdminReportQuery, AdminReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminReportQuery, AdminReportQueryVariables>(AdminReportDocument, options);
      }
export function useAdminReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminReportQuery, AdminReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminReportQuery, AdminReportQueryVariables>(AdminReportDocument, options);
        }
export type AdminReportQueryHookResult = ReturnType<typeof useAdminReportQuery>;
export type AdminReportLazyQueryHookResult = ReturnType<typeof useAdminReportLazyQuery>;
export type AdminReportQueryResult = Apollo.QueryResult<AdminReportQuery, AdminReportQueryVariables>;
export const CreateTokenDocument = gql`
    mutation createToken($userId: String!) {
  createToken(userId: $userId)
}
    `;
export type CreateTokenMutationFn = Apollo.MutationFunction<CreateTokenMutation, CreateTokenMutationVariables>;

/**
 * __useCreateTokenMutation__
 *
 * To run a mutation, you first call `useCreateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTokenMutation, { data, loading, error }] = useCreateTokenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateTokenMutation, CreateTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTokenMutation, CreateTokenMutationVariables>(CreateTokenDocument, options);
      }
export type CreateTokenMutationHookResult = ReturnType<typeof useCreateTokenMutation>;
export type CreateTokenMutationResult = Apollo.MutationResult<CreateTokenMutation>;
export type CreateTokenMutationOptions = Apollo.BaseMutationOptions<CreateTokenMutation, CreateTokenMutationVariables>;
export const ChartDataV2Document = gql`
    query chartDataV2($input: ChartDataInput!) {
  chartDataV2(input: $input) {
    label
    value
  }
}
    `;

/**
 * __useChartDataV2Query__
 *
 * To run a query within a React component, call `useChartDataV2Query` and pass it any options that fit your needs.
 * When your component renders, `useChartDataV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartDataV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChartDataV2Query(baseOptions: Apollo.QueryHookOptions<ChartDataV2Query, ChartDataV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartDataV2Query, ChartDataV2QueryVariables>(ChartDataV2Document, options);
      }
export function useChartDataV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartDataV2Query, ChartDataV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartDataV2Query, ChartDataV2QueryVariables>(ChartDataV2Document, options);
        }
export type ChartDataV2QueryHookResult = ReturnType<typeof useChartDataV2Query>;
export type ChartDataV2LazyQueryHookResult = ReturnType<typeof useChartDataV2LazyQuery>;
export type ChartDataV2QueryResult = Apollo.QueryResult<ChartDataV2Query, ChartDataV2QueryVariables>;
export const DashboardByIdDocument = gql`
    query dashboardById($dashboardId: String!) {
  dashboard(id: $dashboardId) {
    ...DashboardFields
  }
}
    ${DashboardFieldsFragmentDoc}`;

/**
 * __useDashboardByIdQuery__
 *
 * To run a query within a React component, call `useDashboardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardByIdQuery({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useDashboardByIdQuery(baseOptions: Apollo.QueryHookOptions<DashboardByIdQuery, DashboardByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardByIdQuery, DashboardByIdQueryVariables>(DashboardByIdDocument, options);
      }
export function useDashboardByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardByIdQuery, DashboardByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardByIdQuery, DashboardByIdQueryVariables>(DashboardByIdDocument, options);
        }
export type DashboardByIdQueryHookResult = ReturnType<typeof useDashboardByIdQuery>;
export type DashboardByIdLazyQueryHookResult = ReturnType<typeof useDashboardByIdLazyQuery>;
export type DashboardByIdQueryResult = Apollo.QueryResult<DashboardByIdQuery, DashboardByIdQueryVariables>;
export const DashboardBySiteIdDocument = gql`
    query dashboardBySiteId($siteId: String!) {
  dashboardBySiteId(siteId: $siteId) {
    ...DashboardFields
  }
}
    ${DashboardFieldsFragmentDoc}`;

/**
 * __useDashboardBySiteIdQuery__
 *
 * To run a query within a React component, call `useDashboardBySiteIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardBySiteIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardBySiteIdQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useDashboardBySiteIdQuery(baseOptions: Apollo.QueryHookOptions<DashboardBySiteIdQuery, DashboardBySiteIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardBySiteIdQuery, DashboardBySiteIdQueryVariables>(DashboardBySiteIdDocument, options);
      }
export function useDashboardBySiteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardBySiteIdQuery, DashboardBySiteIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardBySiteIdQuery, DashboardBySiteIdQueryVariables>(DashboardBySiteIdDocument, options);
        }
export type DashboardBySiteIdQueryHookResult = ReturnType<typeof useDashboardBySiteIdQuery>;
export type DashboardBySiteIdLazyQueryHookResult = ReturnType<typeof useDashboardBySiteIdLazyQuery>;
export type DashboardBySiteIdQueryResult = Apollo.QueryResult<DashboardBySiteIdQuery, DashboardBySiteIdQueryVariables>;
export const CreateDashboardDocument = gql`
    mutation createDashboard($input: CreateDashboardInput!) {
  createDashboard(input: $input) {
    ...DashboardFields
  }
}
    ${DashboardFieldsFragmentDoc}`;
export type CreateDashboardMutationFn = Apollo.MutationFunction<CreateDashboardMutation, CreateDashboardMutationVariables>;

/**
 * __useCreateDashboardMutation__
 *
 * To run a mutation, you first call `useCreateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDashboardMutation, { data, loading, error }] = useCreateDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDashboardMutation(baseOptions?: Apollo.MutationHookOptions<CreateDashboardMutation, CreateDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDashboardMutation, CreateDashboardMutationVariables>(CreateDashboardDocument, options);
      }
export type CreateDashboardMutationHookResult = ReturnType<typeof useCreateDashboardMutation>;
export type CreateDashboardMutationResult = Apollo.MutationResult<CreateDashboardMutation>;
export type CreateDashboardMutationOptions = Apollo.BaseMutationOptions<CreateDashboardMutation, CreateDashboardMutationVariables>;
export const UpdateDashboardDocument = gql`
    mutation updateDashboard($input: UpdateDashboardInput!) {
  updateDashboard(input: $input) {
    ...DashboardFields
  }
}
    ${DashboardFieldsFragmentDoc}`;
export type UpdateDashboardMutationFn = Apollo.MutationFunction<UpdateDashboardMutation, UpdateDashboardMutationVariables>;

/**
 * __useUpdateDashboardMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardMutation, { data, loading, error }] = useUpdateDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDashboardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDashboardMutation, UpdateDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDashboardMutation, UpdateDashboardMutationVariables>(UpdateDashboardDocument, options);
      }
export type UpdateDashboardMutationHookResult = ReturnType<typeof useUpdateDashboardMutation>;
export type UpdateDashboardMutationResult = Apollo.MutationResult<UpdateDashboardMutation>;
export type UpdateDashboardMutationOptions = Apollo.BaseMutationOptions<UpdateDashboardMutation, UpdateDashboardMutationVariables>;
export const DeleteDashboardDocument = gql`
    mutation deleteDashboard($id: String!) {
  deleteDashboard(id: $id)
}
    `;
export type DeleteDashboardMutationFn = Apollo.MutationFunction<DeleteDashboardMutation, DeleteDashboardMutationVariables>;

/**
 * __useDeleteDashboardMutation__
 *
 * To run a mutation, you first call `useDeleteDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDashboardMutation, { data, loading, error }] = useDeleteDashboardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDashboardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDashboardMutation, DeleteDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDashboardMutation, DeleteDashboardMutationVariables>(DeleteDashboardDocument, options);
      }
export type DeleteDashboardMutationHookResult = ReturnType<typeof useDeleteDashboardMutation>;
export type DeleteDashboardMutationResult = Apollo.MutationResult<DeleteDashboardMutation>;
export type DeleteDashboardMutationOptions = Apollo.BaseMutationOptions<DeleteDashboardMutation, DeleteDashboardMutationVariables>;
export const DuplicateDashboardDocument = gql`
    mutation duplicateDashboard($id: ID!) {
  duplicateDashboard(id: $id) {
    id
  }
}
    `;
export type DuplicateDashboardMutationFn = Apollo.MutationFunction<DuplicateDashboardMutation, DuplicateDashboardMutationVariables>;

/**
 * __useDuplicateDashboardMutation__
 *
 * To run a mutation, you first call `useDuplicateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateDashboardMutation, { data, loading, error }] = useDuplicateDashboardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateDashboardMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateDashboardMutation, DuplicateDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateDashboardMutation, DuplicateDashboardMutationVariables>(DuplicateDashboardDocument, options);
      }
export type DuplicateDashboardMutationHookResult = ReturnType<typeof useDuplicateDashboardMutation>;
export type DuplicateDashboardMutationResult = Apollo.MutationResult<DuplicateDashboardMutation>;
export type DuplicateDashboardMutationOptions = Apollo.BaseMutationOptions<DuplicateDashboardMutation, DuplicateDashboardMutationVariables>;
export const EventTypesDocument = gql`
    query eventTypes {
  eventTypes
}
    `;

/**
 * __useEventTypesQuery__
 *
 * To run a query within a React component, call `useEventTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventTypesQuery(baseOptions?: Apollo.QueryHookOptions<EventTypesQuery, EventTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventTypesQuery, EventTypesQueryVariables>(EventTypesDocument, options);
      }
export function useEventTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventTypesQuery, EventTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventTypesQuery, EventTypesQueryVariables>(EventTypesDocument, options);
        }
export type EventTypesQueryHookResult = ReturnType<typeof useEventTypesQuery>;
export type EventTypesLazyQueryHookResult = ReturnType<typeof useEventTypesLazyQuery>;
export type EventTypesQueryResult = Apollo.QueryResult<EventTypesQuery, EventTypesQueryVariables>;
export const QueueEventsDocument = gql`
    query queueEvents($siteId: String!) {
  queueEvents(siteId: $siteId) {
    ...QueueEventFields
  }
}
    ${QueueEventFieldsFragmentDoc}`;

/**
 * __useQueueEventsQuery__
 *
 * To run a query within a React component, call `useQueueEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueueEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueueEventsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useQueueEventsQuery(baseOptions: Apollo.QueryHookOptions<QueueEventsQuery, QueueEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueueEventsQuery, QueueEventsQueryVariables>(QueueEventsDocument, options);
      }
export function useQueueEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueueEventsQuery, QueueEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueueEventsQuery, QueueEventsQueryVariables>(QueueEventsDocument, options);
        }
export type QueueEventsQueryHookResult = ReturnType<typeof useQueueEventsQuery>;
export type QueueEventsLazyQueryHookResult = ReturnType<typeof useQueueEventsLazyQuery>;
export type QueueEventsQueryResult = Apollo.QueryResult<QueueEventsQuery, QueueEventsQueryVariables>;
export const EventsDocument = gql`
    query events($filters: EventsFilters!) {
  events(filters: $filters) {
    id
    eventType
    createdAt
    cssClass
    cssId
    value
    path
    cmsWebflowCollectionId
    cmsWebflowItemId
    cmsCollectionSlug
    cmsCollectionItemSlug
    formDisplayedAt
    formLastInteractionAt
    formSubmittedAt
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEventsQuery(baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const ReportErrorDocument = gql`
    mutation reportError($input: ReportErrorInput!) {
  reportError(input: $input)
}
    `;
export type ReportErrorMutationFn = Apollo.MutationFunction<ReportErrorMutation, ReportErrorMutationVariables>;

/**
 * __useReportErrorMutation__
 *
 * To run a mutation, you first call `useReportErrorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportErrorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportErrorMutation, { data, loading, error }] = useReportErrorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportErrorMutation(baseOptions?: Apollo.MutationHookOptions<ReportErrorMutation, ReportErrorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportErrorMutation, ReportErrorMutationVariables>(ReportErrorDocument, options);
      }
export type ReportErrorMutationHookResult = ReturnType<typeof useReportErrorMutation>;
export type ReportErrorMutationResult = Apollo.MutationResult<ReportErrorMutation>;
export type ReportErrorMutationOptions = Apollo.BaseMutationOptions<ReportErrorMutation, ReportErrorMutationVariables>;
export const MaintenanceDocument = gql`
    query maintenance {
  maintenance
}
    `;

/**
 * __useMaintenanceQuery__
 *
 * To run a query within a React component, call `useMaintenanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useMaintenanceQuery(baseOptions?: Apollo.QueryHookOptions<MaintenanceQuery, MaintenanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MaintenanceQuery, MaintenanceQueryVariables>(MaintenanceDocument, options);
      }
export function useMaintenanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceQuery, MaintenanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MaintenanceQuery, MaintenanceQueryVariables>(MaintenanceDocument, options);
        }
export type MaintenanceQueryHookResult = ReturnType<typeof useMaintenanceQuery>;
export type MaintenanceLazyQueryHookResult = ReturnType<typeof useMaintenanceLazyQuery>;
export type MaintenanceQueryResult = Apollo.QueryResult<MaintenanceQuery, MaintenanceQueryVariables>;
export const MetricTypesDocument = gql`
    query metricTypes {
  metricTypes
}
    `;

/**
 * __useMetricTypesQuery__
 *
 * To run a query within a React component, call `useMetricTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMetricTypesQuery(baseOptions?: Apollo.QueryHookOptions<MetricTypesQuery, MetricTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricTypesQuery, MetricTypesQueryVariables>(MetricTypesDocument, options);
      }
export function useMetricTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricTypesQuery, MetricTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricTypesQuery, MetricTypesQueryVariables>(MetricTypesDocument, options);
        }
export type MetricTypesQueryHookResult = ReturnType<typeof useMetricTypesQuery>;
export type MetricTypesLazyQueryHookResult = ReturnType<typeof useMetricTypesLazyQuery>;
export type MetricTypesQueryResult = Apollo.QueryResult<MetricTypesQuery, MetricTypesQueryVariables>;
export const MetricsBySiteIdDocument = gql`
    query metricsBySiteId($siteId: ID!) {
  metricsBySiteId(siteId: $siteId) {
    ...MetricFields
  }
}
    ${MetricFieldsFragmentDoc}`;

/**
 * __useMetricsBySiteIdQuery__
 *
 * To run a query within a React component, call `useMetricsBySiteIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsBySiteIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsBySiteIdQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useMetricsBySiteIdQuery(baseOptions: Apollo.QueryHookOptions<MetricsBySiteIdQuery, MetricsBySiteIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricsBySiteIdQuery, MetricsBySiteIdQueryVariables>(MetricsBySiteIdDocument, options);
      }
export function useMetricsBySiteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricsBySiteIdQuery, MetricsBySiteIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricsBySiteIdQuery, MetricsBySiteIdQueryVariables>(MetricsBySiteIdDocument, options);
        }
export type MetricsBySiteIdQueryHookResult = ReturnType<typeof useMetricsBySiteIdQuery>;
export type MetricsBySiteIdLazyQueryHookResult = ReturnType<typeof useMetricsBySiteIdLazyQuery>;
export type MetricsBySiteIdQueryResult = Apollo.QueryResult<MetricsBySiteIdQuery, MetricsBySiteIdQueryVariables>;
export const MetricValuesDocument = gql`
    query metricValues($metricValuesInput: MetricValuesInput!, $filters: MetricFilters) {
  metricValues(input: $metricValuesInput, filters: $filters) {
    label
    values {
      ...MetricValueFields
    }
  }
}
    ${MetricValueFieldsFragmentDoc}`;

/**
 * __useMetricValuesQuery__
 *
 * To run a query within a React component, call `useMetricValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricValuesQuery({
 *   variables: {
 *      metricValuesInput: // value for 'metricValuesInput'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useMetricValuesQuery(baseOptions: Apollo.QueryHookOptions<MetricValuesQuery, MetricValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricValuesQuery, MetricValuesQueryVariables>(MetricValuesDocument, options);
      }
export function useMetricValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricValuesQuery, MetricValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricValuesQuery, MetricValuesQueryVariables>(MetricValuesDocument, options);
        }
export type MetricValuesQueryHookResult = ReturnType<typeof useMetricValuesQuery>;
export type MetricValuesLazyQueryHookResult = ReturnType<typeof useMetricValuesLazyQuery>;
export type MetricValuesQueryResult = Apollo.QueryResult<MetricValuesQuery, MetricValuesQueryVariables>;
export const MetricByValuesDocument = gql`
    query metricByValues($metricValuesInput: MetricValuesInput!, $filters: MetricFilters) {
  metricByValues(input: $metricValuesInput, filters: $filters) {
    name
    value
  }
}
    `;

/**
 * __useMetricByValuesQuery__
 *
 * To run a query within a React component, call `useMetricByValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricByValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricByValuesQuery({
 *   variables: {
 *      metricValuesInput: // value for 'metricValuesInput'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useMetricByValuesQuery(baseOptions: Apollo.QueryHookOptions<MetricByValuesQuery, MetricByValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricByValuesQuery, MetricByValuesQueryVariables>(MetricByValuesDocument, options);
      }
export function useMetricByValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricByValuesQuery, MetricByValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricByValuesQuery, MetricByValuesQueryVariables>(MetricByValuesDocument, options);
        }
export type MetricByValuesQueryHookResult = ReturnType<typeof useMetricByValuesQuery>;
export type MetricByValuesLazyQueryHookResult = ReturnType<typeof useMetricByValuesLazyQuery>;
export type MetricByValuesQueryResult = Apollo.QueryResult<MetricByValuesQuery, MetricByValuesQueryVariables>;
export const MetricByIdDocument = gql`
    query metricById($id: ID!) {
  metric(id: $id) {
    ...MetricFields
  }
}
    ${MetricFieldsFragmentDoc}`;

/**
 * __useMetricByIdQuery__
 *
 * To run a query within a React component, call `useMetricByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMetricByIdQuery(baseOptions: Apollo.QueryHookOptions<MetricByIdQuery, MetricByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricByIdQuery, MetricByIdQueryVariables>(MetricByIdDocument, options);
      }
export function useMetricByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricByIdQuery, MetricByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricByIdQuery, MetricByIdQueryVariables>(MetricByIdDocument, options);
        }
export type MetricByIdQueryHookResult = ReturnType<typeof useMetricByIdQuery>;
export type MetricByIdLazyQueryHookResult = ReturnType<typeof useMetricByIdLazyQuery>;
export type MetricByIdQueryResult = Apollo.QueryResult<MetricByIdQuery, MetricByIdQueryVariables>;
export const CreateMetricDocument = gql`
    mutation createMetric($metric: CreateMetricInput!) {
  createMetric(metric: $metric) {
    ...MetricFields
  }
}
    ${MetricFieldsFragmentDoc}`;
export type CreateMetricMutationFn = Apollo.MutationFunction<CreateMetricMutation, CreateMetricMutationVariables>;

/**
 * __useCreateMetricMutation__
 *
 * To run a mutation, you first call `useCreateMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetricMutation, { data, loading, error }] = useCreateMetricMutation({
 *   variables: {
 *      metric: // value for 'metric'
 *   },
 * });
 */
export function useCreateMetricMutation(baseOptions?: Apollo.MutationHookOptions<CreateMetricMutation, CreateMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMetricMutation, CreateMetricMutationVariables>(CreateMetricDocument, options);
      }
export type CreateMetricMutationHookResult = ReturnType<typeof useCreateMetricMutation>;
export type CreateMetricMutationResult = Apollo.MutationResult<CreateMetricMutation>;
export type CreateMetricMutationOptions = Apollo.BaseMutationOptions<CreateMetricMutation, CreateMetricMutationVariables>;
export const UpdateMetricDocument = gql`
    mutation updateMetric($metric: UpdateMetricInput!) {
  updateMetric(metric: $metric) {
    ...MetricFields
  }
}
    ${MetricFieldsFragmentDoc}`;
export type UpdateMetricMutationFn = Apollo.MutationFunction<UpdateMetricMutation, UpdateMetricMutationVariables>;

/**
 * __useUpdateMetricMutation__
 *
 * To run a mutation, you first call `useUpdateMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMetricMutation, { data, loading, error }] = useUpdateMetricMutation({
 *   variables: {
 *      metric: // value for 'metric'
 *   },
 * });
 */
export function useUpdateMetricMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMetricMutation, UpdateMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMetricMutation, UpdateMetricMutationVariables>(UpdateMetricDocument, options);
      }
export type UpdateMetricMutationHookResult = ReturnType<typeof useUpdateMetricMutation>;
export type UpdateMetricMutationResult = Apollo.MutationResult<UpdateMetricMutation>;
export type UpdateMetricMutationOptions = Apollo.BaseMutationOptions<UpdateMetricMutation, UpdateMetricMutationVariables>;
export const DeleteMetricDocument = gql`
    mutation deleteMetric($metricId: ID!) {
  deleteMetric(metricId: $metricId)
}
    `;
export type DeleteMetricMutationFn = Apollo.MutationFunction<DeleteMetricMutation, DeleteMetricMutationVariables>;

/**
 * __useDeleteMetricMutation__
 *
 * To run a mutation, you first call `useDeleteMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMetricMutation, { data, loading, error }] = useDeleteMetricMutation({
 *   variables: {
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useDeleteMetricMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMetricMutation, DeleteMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMetricMutation, DeleteMetricMutationVariables>(DeleteMetricDocument, options);
      }
export type DeleteMetricMutationHookResult = ReturnType<typeof useDeleteMetricMutation>;
export type DeleteMetricMutationResult = Apollo.MutationResult<DeleteMetricMutation>;
export type DeleteMetricMutationOptions = Apollo.BaseMutationOptions<DeleteMetricMutation, DeleteMetricMutationVariables>;
export const ChartDataDocument = gql`
    query chartData($input: MetricChartInput!) {
  chartData(input: $input) {
    name
    data {
      name
      values {
        label
        value
      }
    }
    delta
  }
}
    `;

/**
 * __useChartDataQuery__
 *
 * To run a query within a React component, call `useChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChartDataQuery(baseOptions: Apollo.QueryHookOptions<ChartDataQuery, ChartDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartDataQuery, ChartDataQueryVariables>(ChartDataDocument, options);
      }
export function useChartDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartDataQuery, ChartDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartDataQuery, ChartDataQueryVariables>(ChartDataDocument, options);
        }
export type ChartDataQueryHookResult = ReturnType<typeof useChartDataQuery>;
export type ChartDataLazyQueryHookResult = ReturnType<typeof useChartDataLazyQuery>;
export type ChartDataQueryResult = Apollo.QueryResult<ChartDataQuery, ChartDataQueryVariables>;
export const MetricsByDashboardIdDocument = gql`
    query metricsByDashboardId($dashboardById: ID!) {
  metricsByDashboardId(dashboardById: $dashboardById) {
    ...MetricFields
  }
}
    ${MetricFieldsFragmentDoc}`;

/**
 * __useMetricsByDashboardIdQuery__
 *
 * To run a query within a React component, call `useMetricsByDashboardIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsByDashboardIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsByDashboardIdQuery({
 *   variables: {
 *      dashboardById: // value for 'dashboardById'
 *   },
 * });
 */
export function useMetricsByDashboardIdQuery(baseOptions: Apollo.QueryHookOptions<MetricsByDashboardIdQuery, MetricsByDashboardIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricsByDashboardIdQuery, MetricsByDashboardIdQueryVariables>(MetricsByDashboardIdDocument, options);
      }
export function useMetricsByDashboardIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricsByDashboardIdQuery, MetricsByDashboardIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricsByDashboardIdQuery, MetricsByDashboardIdQueryVariables>(MetricsByDashboardIdDocument, options);
        }
export type MetricsByDashboardIdQueryHookResult = ReturnType<typeof useMetricsByDashboardIdQuery>;
export type MetricsByDashboardIdLazyQueryHookResult = ReturnType<typeof useMetricsByDashboardIdLazyQuery>;
export type MetricsByDashboardIdQueryResult = Apollo.QueryResult<MetricsByDashboardIdQuery, MetricsByDashboardIdQueryVariables>;
export const OrganisationDocument = gql`
    query organisation($organisationId: ID, $siteId: String) {
  organisation(organisationId: $organisationId, siteId: $siteId) {
    ...OrganisationFields
  }
}
    ${OrganisationFieldsFragmentDoc}`;

/**
 * __useOrganisationQuery__
 *
 * To run a query within a React component, call `useOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useOrganisationQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationQuery, OrganisationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationQuery, OrganisationQueryVariables>(OrganisationDocument, options);
      }
export function useOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationQuery, OrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationQuery, OrganisationQueryVariables>(OrganisationDocument, options);
        }
export type OrganisationQueryHookResult = ReturnType<typeof useOrganisationQuery>;
export type OrganisationLazyQueryHookResult = ReturnType<typeof useOrganisationLazyQuery>;
export type OrganisationQueryResult = Apollo.QueryResult<OrganisationQuery, OrganisationQueryVariables>;
export const PlanExceededDocument = gql`
    query planExceeded($organisationId: ID!) {
  planExceeded(organisationId: $organisationId) {
    planExceeded
    isFreePlanVeryExceeded
    isFreePlanExceededContinuously
    block: isFreePlanVeryExceeded
    viewsBySiteId {
      siteId
      viewCount
    }
  }
}
    `;

/**
 * __usePlanExceededQuery__
 *
 * To run a query within a React component, call `usePlanExceededQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanExceededQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanExceededQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function usePlanExceededQuery(baseOptions: Apollo.QueryHookOptions<PlanExceededQuery, PlanExceededQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanExceededQuery, PlanExceededQueryVariables>(PlanExceededDocument, options);
      }
export function usePlanExceededLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanExceededQuery, PlanExceededQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanExceededQuery, PlanExceededQueryVariables>(PlanExceededDocument, options);
        }
export type PlanExceededQueryHookResult = ReturnType<typeof usePlanExceededQuery>;
export type PlanExceededLazyQueryHookResult = ReturnType<typeof usePlanExceededLazyQuery>;
export type PlanExceededQueryResult = Apollo.QueryResult<PlanExceededQuery, PlanExceededQueryVariables>;
export const SitesDocument = gql`
    query sites($filters: SitesFilters, $first: PositiveInt, $cursor: String, $organisationId: String!) {
  sites(
    filters: $filters
    first: $first
    cursor: $cursor
    organisationId: $organisationId
  ) {
    totalCount
    pageInfo {
      hasNextPage
    }
    edges {
      cursor
      node {
        ...SiteFields
      }
    }
  }
}
    ${SiteFieldsFragmentDoc}`;

/**
 * __useSitesQuery__
 *
 * To run a query within a React component, call `useSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useSitesQuery(baseOptions: Apollo.QueryHookOptions<SitesQuery, SitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
      }
export function useSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SitesQuery, SitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
        }
export type SitesQueryHookResult = ReturnType<typeof useSitesQuery>;
export type SitesLazyQueryHookResult = ReturnType<typeof useSitesLazyQuery>;
export type SitesQueryResult = Apollo.QueryResult<SitesQuery, SitesQueryVariables>;
export const SiteByIdDocument = gql`
    query siteById($id: ID!) {
  siteById(id: $id) {
    ...SiteFields
  }
}
    ${SiteFieldsFragmentDoc}`;

/**
 * __useSiteByIdQuery__
 *
 * To run a query within a React component, call `useSiteByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSiteByIdQuery(baseOptions: Apollo.QueryHookOptions<SiteByIdQuery, SiteByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteByIdQuery, SiteByIdQueryVariables>(SiteByIdDocument, options);
      }
export function useSiteByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteByIdQuery, SiteByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteByIdQuery, SiteByIdQueryVariables>(SiteByIdDocument, options);
        }
export type SiteByIdQueryHookResult = ReturnType<typeof useSiteByIdQuery>;
export type SiteByIdLazyQueryHookResult = ReturnType<typeof useSiteByIdLazyQuery>;
export type SiteByIdQueryResult = Apollo.QueryResult<SiteByIdQuery, SiteByIdQueryVariables>;
export const SiteByWebflowIdDocument = gql`
    query siteByWebflowId($webflowId: String!) {
  siteByWebflowId(webflowId: $webflowId) {
    ...SiteFields
  }
}
    ${SiteFieldsFragmentDoc}`;

/**
 * __useSiteByWebflowIdQuery__
 *
 * To run a query within a React component, call `useSiteByWebflowIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteByWebflowIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteByWebflowIdQuery({
 *   variables: {
 *      webflowId: // value for 'webflowId'
 *   },
 * });
 */
export function useSiteByWebflowIdQuery(baseOptions: Apollo.QueryHookOptions<SiteByWebflowIdQuery, SiteByWebflowIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteByWebflowIdQuery, SiteByWebflowIdQueryVariables>(SiteByWebflowIdDocument, options);
      }
export function useSiteByWebflowIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteByWebflowIdQuery, SiteByWebflowIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteByWebflowIdQuery, SiteByWebflowIdQueryVariables>(SiteByWebflowIdDocument, options);
        }
export type SiteByWebflowIdQueryHookResult = ReturnType<typeof useSiteByWebflowIdQuery>;
export type SiteByWebflowIdLazyQueryHookResult = ReturnType<typeof useSiteByWebflowIdLazyQuery>;
export type SiteByWebflowIdQueryResult = Apollo.QueryResult<SiteByWebflowIdQuery, SiteByWebflowIdQueryVariables>;
export const SiteHtmlElementsDocument = gql`
    query siteHtmlElements($siteId: ID!) {
  siteHtmlElements(siteId: $siteId) {
    html
    id
    text
  }
}
    `;

/**
 * __useSiteHtmlElementsQuery__
 *
 * To run a query within a React component, call `useSiteHtmlElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteHtmlElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteHtmlElementsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSiteHtmlElementsQuery(baseOptions: Apollo.QueryHookOptions<SiteHtmlElementsQuery, SiteHtmlElementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteHtmlElementsQuery, SiteHtmlElementsQueryVariables>(SiteHtmlElementsDocument, options);
      }
export function useSiteHtmlElementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteHtmlElementsQuery, SiteHtmlElementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteHtmlElementsQuery, SiteHtmlElementsQueryVariables>(SiteHtmlElementsDocument, options);
        }
export type SiteHtmlElementsQueryHookResult = ReturnType<typeof useSiteHtmlElementsQuery>;
export type SiteHtmlElementsLazyQueryHookResult = ReturnType<typeof useSiteHtmlElementsLazyQuery>;
export type SiteHtmlElementsQueryResult = Apollo.QueryResult<SiteHtmlElementsQuery, SiteHtmlElementsQueryVariables>;
export const SiteDomainsDocument = gql`
    query siteDomains($siteId: ID!) {
  siteDomains: siteById(id: $siteId) {
    domains
  }
}
    `;

/**
 * __useSiteDomainsQuery__
 *
 * To run a query within a React component, call `useSiteDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteDomainsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSiteDomainsQuery(baseOptions: Apollo.QueryHookOptions<SiteDomainsQuery, SiteDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteDomainsQuery, SiteDomainsQueryVariables>(SiteDomainsDocument, options);
      }
export function useSiteDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteDomainsQuery, SiteDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteDomainsQuery, SiteDomainsQueryVariables>(SiteDomainsDocument, options);
        }
export type SiteDomainsQueryHookResult = ReturnType<typeof useSiteDomainsQuery>;
export type SiteDomainsLazyQueryHookResult = ReturnType<typeof useSiteDomainsLazyQuery>;
export type SiteDomainsQueryResult = Apollo.QueryResult<SiteDomainsQuery, SiteDomainsQueryVariables>;
export const CmsCollectionsDocument = gql`
    query cmsCollections($siteId: ID!) {
  cmsCollections(siteId: $siteId) {
    id
    name
    fields {
      id
      name
      slug
      type
    }
  }
}
    `;

/**
 * __useCmsCollectionsQuery__
 *
 * To run a query within a React component, call `useCmsCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsCollectionsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useCmsCollectionsQuery(baseOptions: Apollo.QueryHookOptions<CmsCollectionsQuery, CmsCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CmsCollectionsQuery, CmsCollectionsQueryVariables>(CmsCollectionsDocument, options);
      }
export function useCmsCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CmsCollectionsQuery, CmsCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CmsCollectionsQuery, CmsCollectionsQueryVariables>(CmsCollectionsDocument, options);
        }
export type CmsCollectionsQueryHookResult = ReturnType<typeof useCmsCollectionsQuery>;
export type CmsCollectionsLazyQueryHookResult = ReturnType<typeof useCmsCollectionsLazyQuery>;
export type CmsCollectionsQueryResult = Apollo.QueryResult<CmsCollectionsQuery, CmsCollectionsQueryVariables>;
export const CmsCollectionsItemsDocument = gql`
    query cmsCollectionsItems($collectionId: ID!) {
  cmsCollectionItems(collectionId: $collectionId) {
    id
    name
  }
}
    `;

/**
 * __useCmsCollectionsItemsQuery__
 *
 * To run a query within a React component, call `useCmsCollectionsItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsCollectionsItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsCollectionsItemsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useCmsCollectionsItemsQuery(baseOptions: Apollo.QueryHookOptions<CmsCollectionsItemsQuery, CmsCollectionsItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CmsCollectionsItemsQuery, CmsCollectionsItemsQueryVariables>(CmsCollectionsItemsDocument, options);
      }
export function useCmsCollectionsItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CmsCollectionsItemsQuery, CmsCollectionsItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CmsCollectionsItemsQuery, CmsCollectionsItemsQueryVariables>(CmsCollectionsItemsDocument, options);
        }
export type CmsCollectionsItemsQueryHookResult = ReturnType<typeof useCmsCollectionsItemsQuery>;
export type CmsCollectionsItemsLazyQueryHookResult = ReturnType<typeof useCmsCollectionsItemsLazyQuery>;
export type CmsCollectionsItemsQueryResult = Apollo.QueryResult<CmsCollectionsItemsQuery, CmsCollectionsItemsQueryVariables>;
export const CheckScriptHeaderDocument = gql`
    mutation checkScriptHeader($siteId: ID!) {
  checkScriptHeader(siteId: $siteId) {
    found
    headerContent
    errorMessage
  }
}
    `;
export type CheckScriptHeaderMutationFn = Apollo.MutationFunction<CheckScriptHeaderMutation, CheckScriptHeaderMutationVariables>;

/**
 * __useCheckScriptHeaderMutation__
 *
 * To run a mutation, you first call `useCheckScriptHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckScriptHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkScriptHeaderMutation, { data, loading, error }] = useCheckScriptHeaderMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useCheckScriptHeaderMutation(baseOptions?: Apollo.MutationHookOptions<CheckScriptHeaderMutation, CheckScriptHeaderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckScriptHeaderMutation, CheckScriptHeaderMutationVariables>(CheckScriptHeaderDocument, options);
      }
export type CheckScriptHeaderMutationHookResult = ReturnType<typeof useCheckScriptHeaderMutation>;
export type CheckScriptHeaderMutationResult = Apollo.MutationResult<CheckScriptHeaderMutation>;
export type CheckScriptHeaderMutationOptions = Apollo.BaseMutationOptions<CheckScriptHeaderMutation, CheckScriptHeaderMutationVariables>;
export const UpdateSiteByIdDocument = gql`
    mutation updateSiteById($input: UpdateSiteByIdInput!) {
  updateSiteById(input: $input) {
    ...SiteFields
  }
}
    ${SiteFieldsFragmentDoc}`;
export type UpdateSiteByIdMutationFn = Apollo.MutationFunction<UpdateSiteByIdMutation, UpdateSiteByIdMutationVariables>;

/**
 * __useUpdateSiteByIdMutation__
 *
 * To run a mutation, you first call `useUpdateSiteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteByIdMutation, { data, loading, error }] = useUpdateSiteByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSiteByIdMutation, UpdateSiteByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSiteByIdMutation, UpdateSiteByIdMutationVariables>(UpdateSiteByIdDocument, options);
      }
export type UpdateSiteByIdMutationHookResult = ReturnType<typeof useUpdateSiteByIdMutation>;
export type UpdateSiteByIdMutationResult = Apollo.MutationResult<UpdateSiteByIdMutation>;
export type UpdateSiteByIdMutationOptions = Apollo.BaseMutationOptions<UpdateSiteByIdMutation, UpdateSiteByIdMutationVariables>;
export const SyncSiteWithWebflowDocument = gql`
    mutation syncSiteWithWebflow($siteId: ID!) {
  syncSiteWithWebflow(siteId: $siteId)
}
    `;
export type SyncSiteWithWebflowMutationFn = Apollo.MutationFunction<SyncSiteWithWebflowMutation, SyncSiteWithWebflowMutationVariables>;

/**
 * __useSyncSiteWithWebflowMutation__
 *
 * To run a mutation, you first call `useSyncSiteWithWebflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncSiteWithWebflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncSiteWithWebflowMutation, { data, loading, error }] = useSyncSiteWithWebflowMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSyncSiteWithWebflowMutation(baseOptions?: Apollo.MutationHookOptions<SyncSiteWithWebflowMutation, SyncSiteWithWebflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncSiteWithWebflowMutation, SyncSiteWithWebflowMutationVariables>(SyncSiteWithWebflowDocument, options);
      }
export type SyncSiteWithWebflowMutationHookResult = ReturnType<typeof useSyncSiteWithWebflowMutation>;
export type SyncSiteWithWebflowMutationResult = Apollo.MutationResult<SyncSiteWithWebflowMutation>;
export type SyncSiteWithWebflowMutationOptions = Apollo.BaseMutationOptions<SyncSiteWithWebflowMutation, SyncSiteWithWebflowMutationVariables>;
export const DeleteSiteDocument = gql`
    mutation deleteSite($siteId: ID!) {
  deleteSite(siteId: $siteId)
}
    `;
export type DeleteSiteMutationFn = Apollo.MutationFunction<DeleteSiteMutation, DeleteSiteMutationVariables>;

/**
 * __useDeleteSiteMutation__
 *
 * To run a mutation, you first call `useDeleteSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSiteMutation, { data, loading, error }] = useDeleteSiteMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useDeleteSiteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSiteMutation, DeleteSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSiteMutation, DeleteSiteMutationVariables>(DeleteSiteDocument, options);
      }
export type DeleteSiteMutationHookResult = ReturnType<typeof useDeleteSiteMutation>;
export type DeleteSiteMutationResult = Apollo.MutationResult<DeleteSiteMutation>;
export type DeleteSiteMutationOptions = Apollo.BaseMutationOptions<DeleteSiteMutation, DeleteSiteMutationVariables>;
export const DeleteDisabledSitesDocument = gql`
    mutation deleteDisabledSites($organisationId: ID!) {
  deleteDisabledSites(organisationId: $organisationId)
}
    `;
export type DeleteDisabledSitesMutationFn = Apollo.MutationFunction<DeleteDisabledSitesMutation, DeleteDisabledSitesMutationVariables>;

/**
 * __useDeleteDisabledSitesMutation__
 *
 * To run a mutation, you first call `useDeleteDisabledSitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDisabledSitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDisabledSitesMutation, { data, loading, error }] = useDeleteDisabledSitesMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useDeleteDisabledSitesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDisabledSitesMutation, DeleteDisabledSitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDisabledSitesMutation, DeleteDisabledSitesMutationVariables>(DeleteDisabledSitesDocument, options);
      }
export type DeleteDisabledSitesMutationHookResult = ReturnType<typeof useDeleteDisabledSitesMutation>;
export type DeleteDisabledSitesMutationResult = Apollo.MutationResult<DeleteDisabledSitesMutation>;
export type DeleteDisabledSitesMutationOptions = Apollo.BaseMutationOptions<DeleteDisabledSitesMutation, DeleteDisabledSitesMutationVariables>;
export const StripeCustomerPortalLinkDocument = gql`
    query stripeCustomerPortalLink {
  stripeCustomerPortalLink
}
    `;

/**
 * __useStripeCustomerPortalLinkQuery__
 *
 * To run a query within a React component, call `useStripeCustomerPortalLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeCustomerPortalLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeCustomerPortalLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeCustomerPortalLinkQuery(baseOptions?: Apollo.QueryHookOptions<StripeCustomerPortalLinkQuery, StripeCustomerPortalLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeCustomerPortalLinkQuery, StripeCustomerPortalLinkQueryVariables>(StripeCustomerPortalLinkDocument, options);
      }
export function useStripeCustomerPortalLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeCustomerPortalLinkQuery, StripeCustomerPortalLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeCustomerPortalLinkQuery, StripeCustomerPortalLinkQueryVariables>(StripeCustomerPortalLinkDocument, options);
        }
export type StripeCustomerPortalLinkQueryHookResult = ReturnType<typeof useStripeCustomerPortalLinkQuery>;
export type StripeCustomerPortalLinkLazyQueryHookResult = ReturnType<typeof useStripeCustomerPortalLinkLazyQuery>;
export type StripeCustomerPortalLinkQueryResult = Apollo.QueryResult<StripeCustomerPortalLinkQuery, StripeCustomerPortalLinkQueryVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  me {
    user {
      ...UserFields
    }
    organisations {
      id
    }
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const NotificationsDocument = gql`
    query notifications {
  me {
    notifications {
      subject
      body
      createdAt
      readAt
    }
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const UsersDocument = gql`
    query users($first: PositiveInt, $cursor: String, $search: String) {
  users(first: $first, cursor: $cursor, search: $search) {
    totalCount
    pageInfo {
      hasNextPage
    }
    edges {
      cursor
      node {
        ...UserFields
      }
    }
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const FeaturesDocument = gql`
    query features {
  features {
    memberFilter
  }
}
    `;

/**
 * __useFeaturesQuery__
 *
 * To run a query within a React component, call `useFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<FeaturesQuery, FeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeaturesQuery, FeaturesQueryVariables>(FeaturesDocument, options);
      }
export function useFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeaturesQuery, FeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeaturesQuery, FeaturesQueryVariables>(FeaturesDocument, options);
        }
export type FeaturesQueryHookResult = ReturnType<typeof useFeaturesQuery>;
export type FeaturesLazyQueryHookResult = ReturnType<typeof useFeaturesLazyQuery>;
export type FeaturesQueryResult = Apollo.QueryResult<FeaturesQuery, FeaturesQueryVariables>;
export const ShortLiveTokenDocument = gql`
    query shortLiveToken {
  shortLiveToken
}
    `;

/**
 * __useShortLiveTokenQuery__
 *
 * To run a query within a React component, call `useShortLiveTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortLiveTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortLiveTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useShortLiveTokenQuery(baseOptions?: Apollo.QueryHookOptions<ShortLiveTokenQuery, ShortLiveTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShortLiveTokenQuery, ShortLiveTokenQueryVariables>(ShortLiveTokenDocument, options);
      }
export function useShortLiveTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShortLiveTokenQuery, ShortLiveTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShortLiveTokenQuery, ShortLiveTokenQueryVariables>(ShortLiveTokenDocument, options);
        }
export type ShortLiveTokenQueryHookResult = ReturnType<typeof useShortLiveTokenQuery>;
export type ShortLiveTokenLazyQueryHookResult = ReturnType<typeof useShortLiveTokenLazyQuery>;
export type ShortLiveTokenQueryResult = Apollo.QueryResult<ShortLiveTokenQuery, ShortLiveTokenQueryVariables>;
export const SignUpWithWebflowDocument = gql`
    mutation signUpWithWebflow($code: String!) {
  signUpWithWebflow(code: $code)
}
    `;
export type SignUpWithWebflowMutationFn = Apollo.MutationFunction<SignUpWithWebflowMutation, SignUpWithWebflowMutationVariables>;

/**
 * __useSignUpWithWebflowMutation__
 *
 * To run a mutation, you first call `useSignUpWithWebflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpWithWebflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpWithWebflowMutation, { data, loading, error }] = useSignUpWithWebflowMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useSignUpWithWebflowMutation(baseOptions?: Apollo.MutationHookOptions<SignUpWithWebflowMutation, SignUpWithWebflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpWithWebflowMutation, SignUpWithWebflowMutationVariables>(SignUpWithWebflowDocument, options);
      }
export type SignUpWithWebflowMutationHookResult = ReturnType<typeof useSignUpWithWebflowMutation>;
export type SignUpWithWebflowMutationResult = Apollo.MutationResult<SignUpWithWebflowMutation>;
export type SignUpWithWebflowMutationOptions = Apollo.BaseMutationOptions<SignUpWithWebflowMutation, SignUpWithWebflowMutationVariables>;
export const SignInDocument = gql`
    mutation signIn($token: String!) {
  signIn(token: $token)
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const RenewTokenDocument = gql`
    mutation renewToken {
  renewToken
}
    `;
export type RenewTokenMutationFn = Apollo.MutationFunction<RenewTokenMutation, RenewTokenMutationVariables>;

/**
 * __useRenewTokenMutation__
 *
 * To run a mutation, you first call `useRenewTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewTokenMutation, { data, loading, error }] = useRenewTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRenewTokenMutation(baseOptions?: Apollo.MutationHookOptions<RenewTokenMutation, RenewTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenewTokenMutation, RenewTokenMutationVariables>(RenewTokenDocument, options);
      }
export type RenewTokenMutationHookResult = ReturnType<typeof useRenewTokenMutation>;
export type RenewTokenMutationResult = Apollo.MutationResult<RenewTokenMutation>;
export type RenewTokenMutationOptions = Apollo.BaseMutationOptions<RenewTokenMutation, RenewTokenMutationVariables>;
export const SendMagicLinkDocument = gql`
    mutation sendMagicLink($email: String!) {
  sendMagicLink(email: $email)
}
    `;
export type SendMagicLinkMutationFn = Apollo.MutationFunction<SendMagicLinkMutation, SendMagicLinkMutationVariables>;

/**
 * __useSendMagicLinkMutation__
 *
 * To run a mutation, you first call `useSendMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMagicLinkMutation, { data, loading, error }] = useSendMagicLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendMagicLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendMagicLinkMutation, SendMagicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMagicLinkMutation, SendMagicLinkMutationVariables>(SendMagicLinkDocument, options);
      }
export type SendMagicLinkMutationHookResult = ReturnType<typeof useSendMagicLinkMutation>;
export type SendMagicLinkMutationResult = Apollo.MutationResult<SendMagicLinkMutation>;
export type SendMagicLinkMutationOptions = Apollo.BaseMutationOptions<SendMagicLinkMutation, SendMagicLinkMutationVariables>;
export const NotificationReadDocument = gql`
    mutation notificationRead($subject: String!) {
  notificationRead(subject: $subject)
}
    `;
export type NotificationReadMutationFn = Apollo.MutationFunction<NotificationReadMutation, NotificationReadMutationVariables>;

/**
 * __useNotificationReadMutation__
 *
 * To run a mutation, you first call `useNotificationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationReadMutation, { data, loading, error }] = useNotificationReadMutation({
 *   variables: {
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useNotificationReadMutation(baseOptions?: Apollo.MutationHookOptions<NotificationReadMutation, NotificationReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationReadMutation, NotificationReadMutationVariables>(NotificationReadDocument, options);
      }
export type NotificationReadMutationHookResult = ReturnType<typeof useNotificationReadMutation>;
export type NotificationReadMutationResult = Apollo.MutationResult<NotificationReadMutation>;
export type NotificationReadMutationOptions = Apollo.BaseMutationOptions<NotificationReadMutation, NotificationReadMutationVariables>;
export const VisitorsDocument = gql`
    query visitors($filters: VisitorsFilters!, $first: PositiveInt, $offset: PositiveInt) {
  visitors(filters: $filters, first: $first, offset: $offset) {
    totalCount
    pageInfo {
      hasNextPage
    }
    edges {
      cursor
      node {
        ...VisitorFields
      }
    }
  }
}
    ${VisitorFieldsFragmentDoc}`;

/**
 * __useVisitorsQuery__
 *
 * To run a query within a React component, call `useVisitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useVisitorsQuery(baseOptions: Apollo.QueryHookOptions<VisitorsQuery, VisitorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitorsQuery, VisitorsQueryVariables>(VisitorsDocument, options);
      }
export function useVisitorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitorsQuery, VisitorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitorsQuery, VisitorsQueryVariables>(VisitorsDocument, options);
        }
export type VisitorsQueryHookResult = ReturnType<typeof useVisitorsQuery>;
export type VisitorsLazyQueryHookResult = ReturnType<typeof useVisitorsLazyQuery>;
export type VisitorsQueryResult = Apollo.QueryResult<VisitorsQuery, VisitorsQueryVariables>;
export const VisitorDocument = gql`
    query visitor($id: ID!, $siteId: ID!) {
  visitor(id: $id, siteId: $siteId) {
    id
    emailAddress
    city
    country
    device
    lastInteraction
    initialReferer
  }
}
    `;

/**
 * __useVisitorQuery__
 *
 * To run a query within a React component, call `useVisitorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorQuery({
 *   variables: {
 *      id: // value for 'id'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useVisitorQuery(baseOptions: Apollo.QueryHookOptions<VisitorQuery, VisitorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitorQuery, VisitorQueryVariables>(VisitorDocument, options);
      }
export function useVisitorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitorQuery, VisitorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitorQuery, VisitorQueryVariables>(VisitorDocument, options);
        }
export type VisitorQueryHookResult = ReturnType<typeof useVisitorQuery>;
export type VisitorLazyQueryHookResult = ReturnType<typeof useVisitorLazyQuery>;
export type VisitorQueryResult = Apollo.QueryResult<VisitorQuery, VisitorQueryVariables>;
export const SiteDetailsPageDocument = gql`
    query SiteDetailsPage($siteId: ID!) {
  site(id: $siteId) {
    id
    name
    isEnabled
    gridLayout {
      id
      x
      y
      w
      h
    }
    metrics {
      ...MetricFields
    }
  }
}
    ${MetricFieldsFragmentDoc}`;

/**
 * __useSiteDetailsPageQuery__
 *
 * To run a query within a React component, call `useSiteDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteDetailsPageQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSiteDetailsPageQuery(baseOptions: Apollo.QueryHookOptions<SiteDetailsPageQuery, SiteDetailsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteDetailsPageQuery, SiteDetailsPageQueryVariables>(SiteDetailsPageDocument, options);
      }
export function useSiteDetailsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteDetailsPageQuery, SiteDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteDetailsPageQuery, SiteDetailsPageQueryVariables>(SiteDetailsPageDocument, options);
        }
export type SiteDetailsPageQueryHookResult = ReturnType<typeof useSiteDetailsPageQuery>;
export type SiteDetailsPageLazyQueryHookResult = ReturnType<typeof useSiteDetailsPageLazyQuery>;
export type SiteDetailsPageQueryResult = Apollo.QueryResult<SiteDetailsPageQuery, SiteDetailsPageQueryVariables>;